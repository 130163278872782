import './phrase.css';
import "bootstrap/dist/css/bootstrap.min.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";

const Phrase = () => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const browserLanguage = navigator.language;
        i18n.changeLanguage(browserLanguage);
    }, [i18n]);
    
    return (
        <div className="secFrase">
            <Container>
                <Row className="organize3">
                    <Col>
                        <h1 className="headerExp3">{t('redefineEmpresaTxt')}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col xs={0} md={1} lg={3}>
                    </Col>
                    <Col xs={12} md={10} lg={6}>
                        <p className="textExp33">{t('crumbsNoSistemaTxt')}</p>
                    </Col>
                    <Col xs={0} md={1} lg={3}>
                    </Col>
                </Row>
                <Row className="contentExp3">
                    <Col>
                        <Row>
                            <Col>
                                <p className="textExp13">{t('operTiempoRealTxt')}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className="textExp23">{t('entrenaEquipoTxt')}<br />
                                {t('evoNegocioTxt')}</p>
                            </Col>
                        </Row>
                        <Row className="colImg3">
                            <Col xs={12} className="colImg3">
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Phrase;