import { useTranslation } from 'react-i18next';
import { useEffect } from "react";

const Help= () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
      const browserLanguage = navigator.language;
      i18n.changeLanguage(browserLanguage);
  }, [i18n]);

    return (
      <main className="BodyContent">
        <h1 className='FAQTitle'>{t('preguntasFrecTxt')}</h1>
      </main>
    );
  }
  
  export default Help;