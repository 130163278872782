import ImageCard from '../Solutions/solutions';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Working1 from "./../../img/Working1.png";
import Working2 from "./../../img/Working2.png";
import Working3 from "./../../img/Working3.png";
import "./workingCards.css";
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";

const WorkCards = () => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const browserLanguage = navigator.language;
        i18n.changeLanguage(browserLanguage);
    }, [i18n]);
    
    return (
        <Row>
            <Col xs={12} lg={4}>
                <ImageCard image={Working1} phrase={t('procRegSelCarTxt')} />
            </Col>
            <Col xs={12} lg={4}>
                <ImageCard image={Working2} phrase={t('digitalizaProcTxt')} />
            </Col>
            <Col xs={12} lg={4}>
                <ImageCard image={Working3} phrase={t('docOrgDigInfoTxt')} />
            </Col>
        </Row>
    );
}

export default WorkCards;