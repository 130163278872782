import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import "./productExp.css";
import Bulb from "./../../img/Bulb.png";
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";

const ProductExp = () => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const browserLanguage = navigator.language;
        i18n.changeLanguage(browserLanguage);
    }, [i18n]);

    return (
        <Container className="ProductExpContainer">
            <Row className="ProdCardRow">
                <Col xs={12} md={6} lg={4} className="ProdCardColumn">
                    <Card className="ProductCard">
                        <Card.Body>
                            <Card.Title className="ProductCardTitle">{t('queEsCrumbsTxt')}</Card.Title>
                            <Card.Text className="ProductCardText">
                                {t('digProcManTxt')}<br />
                                {t('digProcSitTxt')}<br />
                                {t('orgYDocOpsTxt')}
                            </Card.Text>
                            <Card className="ProductCardInside">
                                <Card.Body>
                                    <Row className="ProductCardTextInside">
                                        <ul className="custom-bullets switch">
                                            <li>{t('simpProcTxt')}</li>
                                            <li>{t('selCaracTxt')}</li>
                                            <li>{t('digProcTxt')}</li>
                                        </ul>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={6} lg={4} className="ProdCardColumn">
                    <Card className="ProductCard">
                        <Card.Body>
                            <Card.Title className="ProductCardTitle">{t('ofrCrumbsTxt')}</Card.Title>
                            <Card.Text className="ProductCardText">
                                {t('matOperTxt')}<br />
                                {t('comGrpUsTxt')}<br />
                                {t('fortComunTxt')}
                            </Card.Text>
                            <Card className="ProductCardInside">
                                <Card.Body>
                                    <Row className="ProductCardTextInside">
                                        <ul className="custom-bullets star">
                                            <li>{t('idProcRegTxt')}</li>
                                            <li>{t('docInfDigTxt')}</li>
                                            <li>{t('compRegTxt')}</li>
                                        </ul>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={12} lg={4} className="ProdCardColumn">
                    <Card className="ProductCardImg">
                        <Card.Body>
                            <Card.Title className="ProductCardTitle">{t('propCrumbsTxt')}</Card.Title>
                            <Card.Text className="ProdCardImg">
                                <img src={Bulb} alt="Bulb" className="BulbImg" />
                            </Card.Text>
                            <Card className="ProductCardInsideCheck">
                                <Card.Body>
                                    <Row className="ProductCardTextInside">
                                        <ul className="custom-bullets check">
                                            <li>{t('gestionEmpTxt')}</li>
                                            <li>{t('simplifProcTxt')}</li>
                                            <li>{t('ahorroTiempoTxt')}</li>
                                        </ul>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default ProductExp;