import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";
import ProductExplanation from '../Componentes/ProductExplanation/productExp';

const AboutUs= () => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const browserLanguage = navigator.language;
        i18n.changeLanguage(browserLanguage);
    }, [i18n]);

    return (
      <div className="contProduct BodyContent">
            <Container>
                <Row>
                    <Col xs={12}>
                        <h1 className="ProductTitle">{t('productTitleTxt')}<br/>
                        {t('productSubTitleTxt')}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <ProductExplanation />
                    </Col>
                </Row>
            </Container>
        </div>
    );
  }
  
  export default AboutUs;