const Privacy = () => {
    return (
    <main className="BodyContent">
      <h1>Política de Privacidad</h1>
<p>
    El equipo de Crumbs Software respeta completamente los datos de privacidad de los interesados en nuestros productos, y mantiene los esfuerzos tecnológicos a los que tiene acceso para proteger su información.  
</p>
<p>
    Cualquier información personal proporcionada o recopilada esto controlada por:  
</p>
<p>
    Edgar Adrian Fuentes Flores
    Monterrey México 
    edgar.fuentes@crumbs.software
</p>

<span>
<h3>Cual es la información que recolectamos de tu app y de tu plataforma web?</h3>
<p>Al registrarse en nuestra aplicación y plataforma, se le puede pedir nombre, dirección de correo electrónico y teléfono para ayudarlo a mostrarle la experiencia Crumbs.</p>
</span>

<span>
<h3>Cuando recolectamos la información de tu empresa?</h3>
<p>Recopilamos su información al registrarse en nuestra plataforma y/o en los canales de venta por donde es distribuida la información de mercado de nuestro producto Crumbs.</p>
</span>

<span>
<h3>Como utilizamos tu información?</h3>
<p>Utilizamos tu información para permitirnos ofrecerte la parametrización necesaria para la implementación de Crumbs en tu empresa.</p>
</span>

<span>
<h3>Como esperamos este protegida tu información?</h3>
<p>El acopio de datos de identificación del usuario será efectuada a través del uso del servicio de autenticación de terceros, cumpliendo todos los estándares de la industria o tras el  cumplimentado de un formulario de registro online y se realizará de acuerdo con las más estrictas reglas de seguridad, haciendo uso de los protocolos de encriptación SSL.
    Los datos recogidos en el sitio web o a través de la aplicación móvil son introducidos en el sistema informático que los trata, donde son procesados automáticamente, destinándose estos datos, a la gestión de los servicios del sitio web y aplicación móvil.
    </p>
</span>

<span>
    <h3>Acuerdo con terceras personas</h3>
    <p>Crumbs Sotware ni los proveedores involucrados en el almacenamiento y distribución de tu información venden ni comparten información con terceras personas.</p>
</span>

    </main>
    );
  }
  
  export default Privacy;