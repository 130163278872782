import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./solutions.css"

interface ImageCardProps {
    image: string;
    phrase: string;
}

const ImageCard: React.FC<ImageCardProps> = ({ image, phrase }) => {
    return (
        <Row>
            <Col>
                <Row>
                    <Col className="ColSolutions">
                        <img className="workingImg" src={image} />
                    </Col>
                </Row>
                <Row>
                    <Col className="ColSolutions" xs={12}>
                        <p className="WorkPhrase" dangerouslySetInnerHTML={{ __html: phrase }}></p>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default ImageCard;