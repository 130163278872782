import './footer.css';
import "bootstrap/dist/css/bootstrap.min.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";

const Footer = () => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const browserLanguage = navigator.language;
        i18n.changeLanguage(browserLanguage);
    }, [i18n]);

    return (
        <div className="footer">
            <Container fluid>
                <Row>
                    <Row><p></p></Row>
                    <Col xs={3} md={2} className="txt">
                        <Link to="/help">{t('ayudaTxt')}</Link>
                    </Col>
                    <Col xs={3} md={7}></Col>
                    <Col xs={6} md={3}></Col>
                </Row>
            </Container>
        </div>
    );
}

export default Footer;