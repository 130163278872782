import Prices from '../Componentes/Prices/prices';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";

const Plans = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
      const browserLanguage = navigator.language;
      i18n.changeLanguage(browserLanguage);
  }, [i18n]);

  return (
    <main className="BodyContent">
      <Container>
        <Row><h1 className='planIdeal' style={{ marginTop: 30 }}>
          {t('planIdealTxt')}
        </h1></Row>
        <Row><p className='mejorPlan'>
        {t('cuandoQuierasTxt')}
        </p></Row>
        <div>
          <Prices />
        </div>
      </Container>
    </main>
  );
}

export default Plans;