import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import WorkCards from '../Componentes/WorkingCards/workingCards';
import { Container } from 'react-bootstrap';
import WorkingInitial from '../img/WorkingInitial.png';
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";

const Solution = () => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const browserLanguage = navigator.language;
        i18n.changeLanguage(browserLanguage);
    }, [i18n]);

    return (
        <div className="contSolutions BodyContent">
            <Container>
                <Row>
                    <Col xs={12}>
                        <h1 className="SolTitle">{t('trabFacilTxt')}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <p className="SolPhrase">{t('cadenaSuminTxt')}<br /> 
                            {t('procedSimpleTxt')}<br />
                            {t('clientesProvTxt')}</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Row>
                            <Col xs={12} className='initImgPhrase'>
                                <img className="workInitial" src={WorkingInitial} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className='initImgPhrase'>
                                <p className="SolBottomPhrase">{t('crumbsTodoSumaTxt')}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <WorkCards />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Solution;