import MovsTerm from "./../../img/MovementsTerm.png";
import './explanation2.css';
import "bootstrap/dist/css/bootstrap.min.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";

const Explicacion2 = () => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const browserLanguage = navigator.language;
        i18n.changeLanguage(browserLanguage);
    }, [i18n]);

    return (
        <Container className="exp2">
            <Row className="organize2">
                <Col>
                    <h1 className="headerExp2">{t('potInfoTxt')}</h1>
                </Col>
            </Row>
            <Row className="contentExp2">
                <Col>
                    <Row>
                        <Col>
                            <p className="textExp12">{t('contTiempoRealTxt')}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="textExp22">{t('infoSimpleTxt')} <br/>
                            {t('recursosExisTxt')}</p>
                        </Col>
                    </Row>
                    <Row className="colImg2">
                        <Col xs={12} className="colImg2">
                            <img className="termView2 d-none d-lg-block" src={MovsTerm} alt="App" />
                            <img className="termViewmd2 d-none d-md-block d-lg-none" src={MovsTerm} alt="App" />
                            <img className="termViewxs2 d-block d-md-none" src={MovsTerm} alt="App" />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default Explicacion2;