import CellView from "./../../img/CellphoneView.png";
import Terminal from "./../../img/TerminalView.png";
import './explanation.css';
import "bootstrap/dist/css/bootstrap.min.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";

const Explicacion = () => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const browserLanguage = navigator.language;
        i18n.changeLanguage(browserLanguage);
    }, [i18n]);

    return (
        <Container className="exp">
            <Row className="organize">
                <Col>
                    <h1 className="headerExp">{t('organizaEmpresaTxt')} <br /> {t('diseñaProcsTxt')}</h1>
                </Col>
            </Row>
            <Row className="contentExp">
                <Col>
                    <Row>
                        <Col>
                            <p className="textExp">{t('transformaEmpresaTxt')}</p>
                        </Col>
                    </Row>
                    <Row className="colImg">
                        <Col xs={0} sm={6} className="colImg">
                            <img className="termView d-none d-lg-block" src={Terminal} alt="App" />
                            <img className="termViewmd d-none d-md-block d-lg-none" src={Terminal} alt="App" />
                            <img className="termViewxs d-block d-md-none" src={Terminal} alt="App" />
                        </Col>
                        <Col xs={6} sm={6} md={6} className="colImg">
                            <img className="cellView d-none d-lg-block" src={CellView} alt="App" />
                            <img className="cellViewmd d-none d-md-block d-lg-none" src={CellView} alt="App" />
                            <img className="cellViewxs d-block d-md-none" src={CellView} alt="App" />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p className="textExp2">{t('crumbsPlataformaTxt')}</p>
                </Col>
            </Row>
        </Container>
    );
}

export default Explicacion;