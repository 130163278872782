import React from 'react';
import Explicacion from '.././Componentes/Explanation/explanation';
import Explicacion2 from '.././Componentes/Explanation2/explanation2';
import Phrase from '.././Componentes/Phrase/phrase';
import "bootstrap/dist/css/bootstrap.min.css";

const Home = () => {
  return (
      <main className="BodyContent" >
        <Explicacion/>
        <Explicacion2/>
        <Phrase/>
      </main>
    );
  }
  
  export default Home;