import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        //Header
        productoTxt: "Product",
        solucionesTxt: "Solutions",
        preciosTxt: "Prices",
        iniciarSesionTxt: "Log In",
        demoTxt: "Free DEMO",
        //Footer
        ayudaTxt: "Help",
        //Explanation-Home
        organizaEmpresaTxt: "Organize your company,",
        diseñaProcsTxt: "design your procedures",
        transformaEmpresaTxt: "Lead the transformation of your company and empower your team",
        crumbsPlataformaTxt: "Crumbs is a web-mobile platform that will allow you to optimize the overall time of capture and analysis of your processes",
        //Explanation2-Home
        potInfoTxt: "Enhance your daily information",
        contTiempoRealTxt: "Sort, visualize, and account for your operations in real time",
        infoSimpleTxt: "Capture all the simple information of your company, in optimized time",
        recursosExisTxt: "with existing resources, simplify the information your company handles daily",
        //Phrase-Home
        redefineEmpresaTxt: "Redefine your company",
        crumbsNoSistemaTxt: "Crumbs is not a system, is the flexible information of your company. Review the cash flow you need, the parts that go in and out of your warehouses, trucks, office, document everything you need to centralize the information. Crumbs is the most practical monitoring tool on the market.",
        operTiempoRealTxt: "Sort, visualize, and account for your operations in real time",
        entrenaEquipoTxt: "Train your work team to generate the data you need to maximize your processes and identify errors.",
        evoNegocioTxt: "To converge between administrative, operational, regulatory, and take the next step in the evolution of your business.",
        //ProductPage
        productTitleTxt: "Crumbs, digitize",
        productSubTitleTxt: "simplify and structure",
        //ProductPage-Product Explanation
        queEsCrumbsTxt: "What is Crumbs?",
        digProcManTxt: "Crumbs digitizes manual processes.",
        digProcSitTxt: "Crumbs digitizes processes in remote situations.",
        orgYDocOpsTxt: "Crumbs organizes and documents your operations online and offline.",
        simpProcTxt: "Simplify your procedure",
        selCaracTxt: "Select its features",
        digProcTxt: "Digitize your process",
        ofrCrumbsTxt: "What Crumbs offers?",
        matOperTxt: "Materiality of Operations.",
        comGrpUsTxt: "Community and user groups informed in the cloud.",
        fortComunTxt: "Strengthen the communication of your company's supply chain.",
        idProcRegTxt: "Identify your regulatory processes",
        docInfDigTxt: "Document and organize your digital information",
        compRegTxt: "Share your records with your community",
        propCrumbsTxt: "Crumbs Proposal",
        gestionEmpTxt: "Management, Control, and Communication in your company.",
        simplifProcTxt: "Process simplification",
        ahorroTiempoTxt: "Time saving",
        //Solutions Page
        trabFacilTxt: "Working with software should be easy",
        cadenaSuminTxt: "Your process is part of a supply chain, add efforts and record the manual processes, remote, isolated,",
        procedSimpleTxt: "and the complex ones, transform them into a simple, efficient, self-guided, intuitive procedure for your collaborators",
        clientesProvTxt: "and reliable for your customers and suppliers.",
        crumbsTodoSumaTxt: "Crumbs is cloud, Crumbs is mobile, Crumbs everything ADDS UP.",
        //Solutions Page-Working Cards
        procRegSelCarTxt: "Identify your regulatory processes<br />Select their characteristics",
        digitalizaProcTxt: "Digitize your process",
        docOrgDigInfoTxt: "Document and organize your digital information<br />Share your records with your community",
        //Prices Page
        planIdealTxt: "Find the ideal plan for you",
        cuandoQuierasTxt: "You can change it whenever you want",
        //Prices Page-Plans
        inicialTxt: "Initial",
        treintaDiasTxt: "Free 30 days",
        pruebaGratisTxt: "Free Trial",
        probarTxt: "Try",
        unaEmpresaTxt: "1 Company",
        unaUsuarioTxt: "1 User",
        adminTxt: "(Administrator)",
        unTerminalTxt: "1 Mobile Terminal",
        unCentroTxt: "1 Work Center",
        doscRegTxt: "200 Records",
        movimientosTxt: "(Movements)",
        cuatroscFotVidTxt: "400 Photos/Documents",
        almacenamientoTxt: "(Storage)",
        //
        basicoTxt: "Basic",
        cienDllsTxt: "$100dlls monthly billing",
        suscribirseTxt: "Subscribe",
        logoCusTxt: "(Custom logo)",
        dosUsuariosTxt: "2 Users",
        unAdminTxt: "(1 Administrator)",
        tresTerminalesTxt: "3 Mobile Terminals",
        dosCentrosTxt: "2 Work Centers",
        cuatroscRegTxt: "400 Records",
        ochoscFotVidTxt: "800 Photos/Documents",
        //
        medianoTxt: "Medium",
        doscDllsTxt: "$200 monthly billing",
        multiempresaTxt: "Multi-company",
        diezUsuariosTxt: "10 Users",
        cincoTerminalesTxt: "5 Mobile Terminals",
        diezCentrosTxt: "10 Work Centers",
        milRegTxt: "1,000 Records",
        milSeiscFotVidTxt: "1,600 Photos/Documents",
        //
        avanzadoTxt: "Advanced",
        citaVirtualTxt: "Schedule virtual appointment",
        agendarTxt: "Schedule",
        cienUsuariosTxt: "100 Users",
        intAPITxt: "API Integration",
        //FAQ Page
        preguntasFrecTxt: "Frequently Asked Questions",
      }
    },
    es: {
      translation: {
        //Header
        productoTxt: "Producto",
        solucionesTxt: "Soluciones",
        preciosTxt: "Precios",
        iniciarSesionTxt: "Iniciar Sesión",
        demoTxt: "DEMO Gratis",
        //Footer
        ayudaTxt: "Ayuda",
        //Explanation-Home
        organizaEmpresaTxt: "Organiza tu empresa,",
        diseñaProcsTxt: "diseña tus procedimientos",
        transformaEmpresaTxt: "Lidera la transformación de tu empresa y potencializa a tu equipo de trabajo",
        crumbsPlataformaTxt: "Crumbs es una plataforma web-movil que te permitirá optimizar tiempos de captura y análisis de tus procesos",
        //Explanation2-Home
        potInfoTxt: "Potencializa tu información diaria",
        contTiempoRealTxt: "Ordena, visualiza y contabiliza en tiempo real tus operaciones",
        infoSimpleTxt: "Captura toda la información simple de tu empresa, en tiempos optimizados",
        recursosExisTxt: "con recursos existentes, simplifica la información que tu empresa realiza diariamente",
        //Phrase-Home
        redefineEmpresaTxt: "Redefine tu empresa",
        crumbsNoSistemaTxt: "Crumbs no es un sistema, es la información flexible de tu empresa, revisa el flujo de efectivo que necesitas, las piezas que salen y entran a tus almacenes, camiones, oficina, documenta todo lo necesario para centralizar la información, Crumbs es la herramienta de monitoreo más práctica del mercado.",
        operTiempoRealTxt: "Ordena, visualiza y contabiliza en tiempo real tus operaciones",
        entrenaEquipoTxt: "Entrena a tu equipo de trabajo para que genere los datos que necesitas para maximizar tus procesos, para identificar los errores.",
        evoNegocioTxt: "Para converger entre lo administrativo, lo operativo, lo regulatorio, y dar el siguiente paso en la evolución de tu negocio.",
        //ProductPage
        productTitleTxt: "Crumbs, digitaliza",
        productSubTitleTxt: "simplifica y estructura",
        //ProductPage-Product Explanation
        queEsCrumbsTxt: "¿Qué es Crumbs?",
        digProcManTxt: "Crumbs digitaliza procesos manuales.",
        digProcSitTxt: "Crumbs digitaliza procesos en situaciones remotas.",
        orgYDocOpsTxt: "Crumbs organiza y documenta tus operaciones online y offline.",
        simpProcTxt: "Simplifica tu procedimiento",
        selCaracTxt: "Selecciona sus características",
        digProcTxt: "Digitaliza tu proceso",
        ofrCrumbsTxt: "¿Qué ofrece Crumbs?",
        matOperTxt: "Materialidad de Operaciones.",
        comGrpUsTxt: "Comunidad y grupos de usuarios informados en la nube.",
        fortComunTxt: "Fortalece la comunicación de la cadena de suministro de tu empresa.",
        idProcRegTxt: "Identifica tus procesos regulatorios",
        docInfDigTxt: "Documenta y organiza tu información digital",
        compRegTxt: "Comparte tus registros a tu comunidad",
        propCrumbsTxt: "Propuesta de Crumbs",
        gestionEmpTxt: "Gestión, Control y Comunicación en tu empresa.",
        simplifProcTxt: "Simplificación de procesos",
        ahorroTiempoTxt: "Ahorro de tiempo",
        //Solutions Page
        trabFacilTxt: "Trabajar con un software debe ser fácil",
        cadenaSuminTxt: "Tu proceso, es parte de una cadena de suministro, suma esfuerzos y registra los procesos manuales, remotos y aislados,",
        procedSimpleTxt: "y los complejos, transfórmalos en un procedimiento simple, eficiente, autoguiado, intuitivo para tus colaboradores",
        clientesProvTxt: "y confiables para tus clientes y proveedores.",
        crumbsTodoSumaTxt: "Crumbs es cloud, Crumbs es móvil, Crumbs todo SUMA.",
        //Solutions Page-Working Cards
        procRegSelCarTxt: "Identifica tus procesos regulatorios<br />Selecciona sus características",
        digitalizaProcTxt: "Digitaliza tu proceso",
        docOrgDigInfoTxt: "Documenta y organiza tu información digital<br />Comparte tus registros a tu comunidad",
        //Prices Page
        planIdealTxt: "Encuentra el plan ideal para ti",
        cuandoQuierasTxt: "Puedes cambiarlo cuando tu quieras",
        //Prices Page-Plans
        inicialTxt: "Inicial",
        treintaDiasTxt: "Gratis 30 días",
        pruebaGratisTxt: "Prueba Gratis",
        probarTxt: "Probar",
        unaEmpresaTxt: "1 Empresa",
        unaUsuarioTxt: "1 Usuario",
        adminTxt: "(Administrador)",
        unTerminalTxt: "1 Terminal Móvil",
        unCentroTxt: "1 Centro de Trabajo",
        doscRegTxt: "200 Registros",
        movimientosTxt: "(Movimientos)",
        cuatroscFotVidTxt: "400 Fotos/Documentos",
        almacenamientoTxt: "(Almacenamiento)",
        //
        basicoTxt: "Básico",
        cienDllsTxt: "$100dlls facturación mensual",
        suscribirseTxt: "Suscribirse",
        logoCusTxt: "(Logo customizado)",
        dosUsuariosTxt: "2 Usuarios",
        unAdminTxt: "(1 Administrador)",
        tresTerminalesTxt: "3 Terminales Móviles",
        dosCentrosTxt: "2 Centros de Trabajo",
        cuatroscRegTxt: "400 Registros",
        ochoscFotVidTxt: "800 Fotos/Documentos",
        //
        medianoTxt: "Mediano",
        doscDllsTxt: "$200dlls facturación mensual",
        multiempresaTxt: "Multiempresa",
        diezUsuariosTxt: "10 Usuarios",
        cincoTerminalesTxt: "5 Terminales Móviles",
        diezCentrosTxt: "10 Centros de Trabajo",
        milRegTxt: "1,000 Registros",
        milSeiscFotVidTxt: "1,600 Fotos/Documentos",
        //
        avanzadoTxt: "Avanzado",
        citaVirtualTxt: "Agendar cita virtual",
        agendarTxt: "Agendar",
        cienUsuariosTxt: "100 Usuarios",
        intAPITxt: "Integración con API´s",
        //FAQ Page
        preguntasFrecTxt: "Preguntas más frecuentes",
      }
    },
    pt: {
      translation: {
        //Header
        productoTxt: "Produto",
        solucionesTxt: "Soluções",
        preciosTxt: "Preços",
        iniciarSesionTxt: "Iniciar Sessão",
        demoTxt: "DEMO Grátis",
        //Footer
        ayudaTxt: "Ajuda",
        //Explanation-Home
        organizaEmpresaTxt: "Organize sua empresa,",
        diseñaProcsTxt: "desenhe seus procedimentos",
        transformaEmpresaTxt: "Lidere a transformação da sua empresa e potencialize sua equipe",
        crumbsPlataformaTxt: "Crumbs é uma plataforma web-móvel que permitirá otimizar os tempos de captura e análise de seus processos",
        //Explanation2-Home
        potInfoTxt: "Potencialize suas informações diárias",
        contTiempoRealTxt: "Organize, visualize e contabilize suas operações em tempo real",
        infoSimpleTxt: "Capture todas as informações simples da sua empresa, em tempos otimizados",
        recursosExisTxt: "com recursos existentes, simplifique as informações que sua empresa realiza diariamente",
        //Phrase-Home
        redefineEmpresaTxt: "Redefina sua empresa",
        crumbsNoSistemaTxt: "Crumbs não é um sistema, é a informação flexível da sua empresa. Revise o fluxo de caixa que você precisa, as peças que entram e saem de seus armazéns, caminhões, escritório, documente tudo o que é necessário para centralizar as informações. Crumbs é a ferramenta de monitoramento mais prática do mercado.",
        operTiempoRealTxt: "Organize, visualize e contabilize suas operações em tempo real",
        entrenaEquipoTxt: "Treine sua equipe de trabalho para gerar os dados que você precisa para maximizar seus processos e identificar erros.",
        evoNegocioTxt: "Para convergir entre o administrativo, o operacional, o regulatório e dar o próximo passo na evolução do seu negócio.",
        //ProductPage
        productTitleTxt: "Crumbs, digitalize",
        productSubTitleTxt: "simplifique e estruture",
        //ProductPage-Product Explanation
        queEsCrumbsTxt: "O que é Crumbs?",
        digProcManTxt: "Crumbs digitaliza processos manuais.",
        digProcSitTxt: "Crumbs digitaliza processos em situações remotas.",
        orgYDocOpsTxt: "Crumbs organiza e documenta suas operações online e offline.",
        simpProcTxt: "Simplifique seu procedimento",
        selCaracTxt: "Selecione suas características",
        digProcTxt: "Digitalize seu processo",
        ofrCrumbsTxt: "O que Crumbs oferece?",
        matOperTxt: "Materialidade das Operações.",
        comGrpUsTxt: "Comunidade e grupos de usuários informados na nuvem.",
        fortComunTxt: "Fortaleça a comunicação da cadeia de suprimentos da sua empresa.",
        idProcRegTxt: "Identifique seus processos regulatórios",
        docInfDigTxt: "Documente e organize suas informações digitais",
        compRegTxt: "Compartilhe seus registros com sua comunidade",
        propCrumbsTxt: "Proposta do Crumbs",
        gestionEmpTxt: "Gestão, Controle e Comunicação na sua empresa.",
        simplifProcTxt: "Simplificação de processos",
        ahorroTiempoTxt: "Economia de tempo",
        //Solutions Page
        trabFacilTxt: "Trabalhar com software deve ser fácil",
        cadenaSuminTxt: "Seu processo é parte de uma cadeia de suprimentos, some esforços e registre processos manuais, remotos e isolados,",
        procedSimpleTxt: "e complexos, transforme-os em um procedimento simples, eficiente, autoguiado e intuitivo para seus colaboradores",
        clientesProvTxt: "e confiável para seus clientes e fornecedores.",
        crumbsTodoSumaTxt: "Crumbs é nuvem, Crumbs é móvel, Crumbs tudo SOMA.",
        //Solutions Page-Working Cards
        procRegSelCarTxt: "Identifique seus processos regulatórios<br />Selecione suas características",
        digitalizaProcTxt: "Digitalize seu processo",
        docOrgDigInfoTxt: "Documente e organize suas informações digitais<br />Compartilhe seus registros com sua comunidade",
        //Prices Page
        planIdealTxt: "Encontre o plano ideal para você",
        cuandoQuierasTxt: "Você pode mudá-lo quando quiser",
        //Prices Page-Plans
        inicialTxt: "Inicial",
        treintaDiasTxt: "30 dias grátis",
        pruebaGratisTxt: "Teste Grátis",
        probarTxt: "Experimentar",
        unaEmpresaTxt: "1 Empresa",
        unaUsuarioTxt: "1 Usuário",
        adminTxt: "(Administrador)",
        unTerminalTxt: "1 Terminal Móvel",
        unCentroTxt: "1 Centro de Trabalho",
        doscRegTxt: "200 Registros",
        movimientosTxt: "(Movimentos)",
        cuatroscFotVidTxt: "400 Fotos/Documentos",
        almacenamientoTxt: "(Armazenamento)",
        //
        basicoTxt: "Básico",
        cienDllsTxt: "$100dlls faturamento mensal",
        suscribirseTxt: "Inscrever-se",
        logoCusTxt: "(Logo personalizado)",
        dosUsuariosTxt: "2 Usuários",
        unAdminTxt: "(1 Administrador)",
        tresTerminalesTxt: "3 Terminais Móveis",
        dosCentrosTxt: "2 Centros de Trabalho",
        cuatroscRegTxt: "400 Registros",
        ochoscFotVidTxt: "800 Fotos/Documentos",
        //
        medianoTxt: "Médio",
        doscDllsTxt: "$200 faturamento mensal",
        multiempresaTxt: "Multiempresa",
        diezUsuariosTxt: "10 Usuários",
        cincoTerminalesTxt: "5 Terminais Móveis",
        diezCentrosTxt: "10 Centros de Trabalho",
        milRegTxt: "1,000 Registros",
        milSeiscFotVidTxt: "1,600 Fotos/Documentos",
        //
        avanzadoTxt: "Avançado",
        citaVirtualTxt: "Agendar consulta virtual",
        agendarTxt: "Agendar",
        cienUsuariosTxt: "100 Usuários",
        intAPITxt: "Integração com API's",
        //FAQ Page
        preguntasFrecTxt: "Perguntas Frequentes",
      }
    }
  },
  lng: "en", // idioma por defecto
  fallbackLng: "en", // idioma de respaldo si no se encuentra el actual
  interpolation: {
    escapeValue: false, // React ya escapa los valores
  }
});

export default i18n;