import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import "./prices.css"
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";

const Prices = () => {
    const { t, i18n } = useTranslation();
  
    useEffect(() => {
        const browserLanguage = navigator.language;
        i18n.changeLanguage(browserLanguage);
    }, [i18n]);

    return (
        <Container className="precios">
            <Row>
                <Col xxl={3} md={6} xs={12} className="colSup">
                    <Row>
                        <Col>
                            <Row>
                                <h1 className="overCard">{t('inicialTxt')}</h1>
                            </Row>
                            <Row>
                                <p className="textDetail">{t('treintaDiasTxt')}</p>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='rowCards' >
                        <Card className="card">
                            <Card.Body>
                                <Card.Title className="titulo">{t('pruebaGratisTxt')}</Card.Title>
                                <Row>
                                    <Link to='/solutions'><button className="botonPreciosProbar">{t('probarTxt')}</button></Link>
                                </Row>
                                <Row className="fila">
                                    <ul className="list">
                                        <li>
                                         {t('unaEmpresaTxt')}
                                        </li>
                                        <li>
                                            <span style={{ color: 'black', }}>{t('unaUsuarioTxt')}</span> 
                                            <span style={{ color: '#414141', fontWeight:200 }}>{t('adminTxt')}</span>
                                        </li>
                                        <li>
                                            {t('unTerminalTxt')}
                                        </li>
                                        <li>
                                            {t('unCentroTxt')}
                                        </li>
                                        <li>
                                            <span style={{ color: 'black', }}>{t('doscRegTxt')}</span> 
                                            <span style={{ color: '#414141', fontWeight:200 }}>{t('movimientosTxt')}</span>
                                        </li>
                                        <li>
                                            <span style={{ color: 'black'}}>{t('cuatroscFotVidTxt')} <br/></span> 
                                            <span style={{ color: '#414141', fontWeight:200}}>{t('almacenamientoTxt')}</span>
                                        </li>
                                    </ul>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Row>
                </Col>
                <Col xxl={3} md={6} xs={12} className="colSup">
                    <Row>
                        <Col>
                            <Row>
                                <h1 className="overCard">{t('basicoTxt')}</h1>
                            </Row>
                            <Row>
                                <p className="textDetail">{t('cienDllsTxt')}</p>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='rowCards' >
                        <Card className="card">
                            <Card.Body>
                                <Card.Title className="titulo">100dlls</Card.Title>
                                <Row>
                                    <Link to='/solutions'><button className="botonPrecios">{t('suscribirseTxt')}</button></Link>
                                </Row>
                                <Row className="fila">
                                    <ul className="list">
                                        <li>
                                            <span style={{ color: 'black', }}>{t('unaEmpresaTxt')} <br/></span> 
                                            <span style={{ color: '#414141', fontWeight:200 }}>{t('logoCusTxt')}</span>
                                        </li>
                                        <li>
                                            <span style={{ color: 'black', }}>{t('dosUsuariosTxt')}</span> 
                                            <span style={{ color: '#414141', fontWeight:200 }}>{t('unAdminTxt')}</span>
                                        </li>
                                        <li>
                                            {t('tresTerminalesTxt')}
                                        </li>
                                        <li>
                                            {t('dosCentrosTxt')}
                                        </li>
                                        <li>
                                            <span style={{ color: 'black', }}>{t('cuatroscRegTxt')} </span> 
                                            <span style={{ color: '#414141', fontWeight:200 }}>{t('movimientosTxt')}</span>
                                        </li>
                                        <li>
                                            <span style={{ color: 'black'}}>{t('ochoscFotVidTxt')} </span> 
                                            <span style={{ color: '#414141', fontWeight:200}}>{t('almacenamientoTxt')}</span>
                                        </li>
                                    </ul>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Row>
                </Col>
                <Col xxl={3} md={6} xs={12} className="colSup">
                    <Row>
                        <Col>
                            <Row>
                                <h1 className="overCard">{t('medianoTxt')}</h1>
                            </Row>
                            <Row>
                                <p className="textDetail">{t('doscDllsTxt')}</p>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='rowCards' >
                        <Card className="card">
                            <Card.Body>
                                <Card.Title className="titulo">200dlls</Card.Title>
                                <Row>
                                    <Link to='/solutions'><button className="botonPrecios">{t('suscribirseTxt')}</button></Link>
                                </Row>
                                <Row className="fila">
                                    <ul className="list">
                                        <li>
                                        {t('multiempresaTxt')}
                                        </li>
                                        <li>
                                        {t('diezUsuariosTxt')}
                                        </li>
                                        <li>
                                        {t('cincoTerminalesTxt')}
                                        </li>
                                        <li>
                                        {t('diezCentrosTxt')}
                                        </li>
                                        <li>
                                            <span style={{ color: 'black', }}>{t('milRegTxt')} </span> 
                                            <span style={{ color: '#414141', fontWeight:200 }}>{t('movimientosTxt')}</span>
                                        </li>
                                        <li>
                                            <span style={{ color: 'black'}}>{t('milSeiscFotVidTxt')} </span> 
                                            <span style={{ color: '#414141', fontWeight:200}}>{t('almacenamientoTxt')}</span>
                                        </li>
                                    </ul>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Row>
                </Col>
                <Col xxl={3} md={6} xs={12} className="colSup">
                    <Row>
                        <Col>
                            <Row>
                                <h1 className="overCard">{t('avanzadoTxt')}</h1>
                            </Row>
                            <Row>
                                <p className="textDetail">{t('citaVirtualTxt')}</p>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='rowCards' >
                        <Card className="card">
                            <Card.Body>
                                <Card.Title className="titulo" style={{color: 'white'}}> ' </Card.Title>
                                <Row>
                                    <Link to='/solutions'><button className="botonPrecios">{t('agendarTxt')}</button></Link>
                                </Row>
                                <Row className="fila">
                                    <ul className="list">
                                        <li>
                                            {t('multiempresaTxt')}
                                        </li>
                                        <li>
                                            {t('cienUsuariosTxt')}
                                        </li>
                                        <li>
                                            {t('intAPITxt')}
                                        </li>
                                    </ul>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default Prices;